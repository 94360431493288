body {
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: #0087CD;
  text-decoration: none;
}

/* Keep nested CSS selector to get higher priority than default CSS declaration */
.Toastify .Toastify__toast--success {
  background: #79b981;
}
.Toastify .Toastify__toast--warning {
  background: #DB9143;
}
.Toastify .Toastify__toast--error {
  background: #E05050;
}
